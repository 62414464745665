<template>
  <div class="header-bg">
    <div class="header">
      <div class="header-left">
        <img class="logo" src="../../assets/img/logo/luckcoinz.webp" alt="" @click="router.push('/')" />
      </div>

      <div class="header-right">
        <template v-if="isLogin">
          <div class="my-box" @click="showMenu = true" v-click-outside="() => (showMenu = false)">
            <img class="acc-icon" src="@/assets/img/icons/active/account_icon.svg" alt="" />

            <div class="my-info">
              {{ userStore.account }}

              <div class="bls">
                {{ formatAmount(totalBalance.toFixed(2)) }}
              </div>
            </div>

            <img class="arr-icon" src="@/assets/img/icons/active/arrow.svg" alt="" />

            <div class="my-menu" v-if="showMenu">
              <div class="user-info">
                <img class="logo" src="https://w365-boproduction-storage.w365b.com/vips/icons/dBQTqHQQlVxl.webp"
                  alt="" />
                <div>{{ userStore.account }}</div>

                <img class="mail" :src="emailIcon" alt="" @click="router.push('/notes')" />
              </div>

              <user-menu />
            </div>
          </div>
        </template>

        <template v-else>
          <div class="lr-btns">
            <div class="login-btn" @click="configStore.showLoginDialog = true">
              {{ $t('login') }}
            </div>
            <div class="join-btn" @click="router.push('/register')">
              {{ $t('register') }}
            </div>
          </div>
        </template>

        <div @click="showLanguageSelect = !showLanguageSelect" class="lang-box"
          v-click-outside="() => (showLanguageSelect = false)">
          <img class="lang-icon" :src="langMap[language].icon" />

          <svg viewBox="0 0 24 24" aria-hidden="true">
            <path d="M7 10l5 5 5-5H7z"></path>
          </svg>
          <transition>
            <div v-show="showLanguageSelect"
              class="lang-list absolute text-white text-sm right-0 top-6 p-2 border border-gray-500 rounded-md pl-3 pr-3 shadow-md shadow-gray-900 animate__animated"
              :class="showLanguageSelect ? 'animate__zoomIn' : 'animate__zoomOut'" style="background: #373942">
              <template v-for="(o, k) in langMap">
                <div class="lang-item" @click="changeLanguage(k)" v-if="language !== k">
                  <img class="lang-icon inline-block" :src="o.icon" :alt="o.name" />
                  <span class="ml-1 inline-block">{{ o.name }}</span>
                </div>
              </template>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
  <div class="head-menu-bg">
    <div class="head-menu-list">
      <div class="head-menu-item" v-for="(o, i) in menuList" @click="router.push(o.path)"
        :class="{ on: o.path === currentRoute }">
        <img src="@/assets/img/v2/menu/live.webp" alt="" v-if="i === 0" />
        {{ $t(o.title) }}
      </div>

<!--      <div class="prom-btn" @click="router.push('/promotions')">-->
<!--        <img src="@/assets/img/icons/home-promotion.svg" alt="" />-->
<!--        {{ $t('promotions') }}-->
<!--      </div>-->
    </div>
  </div>
</template>

<script setup>
import { useConfigStore } from '@/stores/config.js'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import { getToken } from '@/utils/token.js'
import { useUserStore } from '@/stores/user.js'
import { formatAmount } from '@/utils/utils.js'
import UserMenu from '@/components/UserMenu/UserMenu.vue'
import { storeToRefs } from 'pinia'
import { useCommonStore } from '@/stores/common.js'
import { useRoute, useRouter } from 'vue-router'

import cnIcon from '@/assets/img/icon/cn.webp'
import enIcon from '@/assets/img/icon/en.webp'
import koIcon from '@/assets/img/icon/ko.webp'
import thIcon from '@/assets/img/icon/th.webp'
import vnIcon from '@/assets/img/icon/vn.webp'
import mmIcon from '@/assets/img/icon/mm.webp'
import lkIcon from '@/assets/img/icon/lk.webp'
import emailIcon from '@/assets/img/email-icon.svg'

const langMap = ref({
  lk: {
    name: 'ශ්‍රී ලංකා',
    icon: lkIcon,
  },
  en: {
    name: 'English',
    icon: enIcon,
  },
  // ko: {
  //     name: '한국어',
  //     icon: koIcon,
  // },

})

const router = useRouter()
const route = useRoute()

const configStore = useConfigStore()
const showLanguageSelect = ref(false)
const instance = getCurrentInstance()
const $t = instance.appContext.config.globalProperties.$t
const language = ref(configStore.language)

const i18n = instance.appContext.config.globalProperties.$i18n
const changeLanguage = (name) => {
  configStore.language = name
  language.value = name
  i18n.locale = name
}
const isLogin = ref(Boolean(getToken()))
const userStore = useUserStore()
onMounted(() => {
  if (isLogin.value) {
    userStore.updateInfo()
  }
})
const { showMenu } = storeToRefs(useCommonStore())

const menuList = [
  {
    title: 'liveCasino',
    path: '/live-casino',
  },
  {
    title: 'sport',
    path: '/sports',
  },
  {
    title: 'slot',
    path: '/slots',
  },

  {
    title: 'deposit',
    path: '/deposit',
  },
  // {
  //   title: 'notice',
  //   path: '/notices',
  // },
]

const currentRoute = computed(() => {
  return route.path
})

const totalBalance = computed(
  () =>
    Number(userStore.balance) +
    Number(userStore.cash > 0 ? userStore.cash : 0) +
    Number(userStore.sport_bonus) +
    Number(userStore.live_slots_bonus) +
    Number(userStore.coupon)
)
</script>

<style scoped lang="less">
.header-bg {
  width: 100%;
  background-color: #121722;
  padding: 0 80 * @pcx;

  .header {
    width: 100%;
    margin: auto;
    height: 100 * @pcx;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-left {
      display: flex;
      align-items: center;
      position: relative;

      img {
        height: 65 * @pcx;
        object-fit: contain;
        object-position: left;
        cursor: pointer;

        &:nth-child(n + 2) {
          margin-left: 12 * @pcx;
        }
      }
    }

    #tawkchat-minified {
      display: none !important;
    }

    .header-right {
      display: flex;
      align-items: center;

      .my-box {
        background: #141a27;
        border-radius: 50 * @pcx 50 * @pcx 50 * @pcx 50 * @pcx;
        border: 1 * @pcx solid #323849;
        position: relative;
        padding: 3 * @pcx 25 * @pcx;
        display: flex;
        align-items: center;
        cursor: pointer;

        .acc-icon {
          width: 24 * @pcx;
          height: 24 * @pcx;
          object-fit: contain;
        }

        .arr-icon {
          width: 16 * @pcx;
          height: 16 * @pcx;
          object-fit: contain;
          transform: rotateZ(90deg);
        }

        .my-info {
          margin: 0 20 * @pcx;
          font-family: Roboto, Roboto, sans-serif;
          font-weight: bold;
          font-size: 17 * @pcx;
          color: #ffffff;
          line-height: 20 * @pcx;
          text-align: left;
          font-style: normal;
          text-transform: none;

          .bls {
            margin-top: 6 * @pcx;
            color: #ff494b;
          }
        }

        .my-menu {
          position: absolute;
          z-index: 99;
          top: 100%;
          right: 0;
          overflow: hidden;
          border-radius: 15 * @pcx;
          border: 1 * @pcx solid #1b1f28;
          border-radius: 15 * @pcx;
          background: #090c13;
          width: 350 * @pcx;

          .user-info {
            padding: 20 * @pcx 20 * @pcx;
            display: flex;
            align-items: center;
            font-family: Roboto, Roboto, sans-serif;
            font-weight: bold;
            font-size: 17 * @pcx;
            color: #ffffff;
            line-height: 20 * @pcx;
            text-align: left;
            font-style: normal;
            text-transform: none;

            .logo {
              width: 24 * @pcx;
              margin-right: 10 * @pcx;
            }

            .mail {
              margin-left: auto;
              width: 24 * @pcx;
              cursor: pointer;
            }
          }
        }
      }

      .lr-btns {
        width: 340 * @pcx;
        height: 54 * @pcx;
        background: #141a27;
        border-radius: 50 * @pcx;
        border: 1 * @pcx solid #323849;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Roboto, Roboto, sans-serif;
        font-weight: bold;
        font-size: 15 * @pcx;
        color: #ffffff;
        line-height: 18 * @pcx;
        text-align: left;
        font-style: normal;
        text-transform: none;
        overflow: hidden;

        .login-btn,
        .join-btn {
          flex: 1;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .join-btn {
          border-radius: 50 * @pcx;
          background: linear-gradient(180deg, #4d84fe 0%, #664fff 64%, #703eff 100%);
          box-shadow: inset 0 * @pcx 4 * @pcx 4 * @pcx 1 * @pcx #6a8df0;
        }
      }

      .menu-btn {
        width: 32 * @pcx;
        height: 32 * @pcx;
        display: flex;
        position: relative;
        margin-left: 12 * @pcx;

        .menu-btn-icon {
          width: 100%;
          height: 100%;
          fill: white;
        }
      }

      .lang-box {
        position: relative;
        z-index: 9;
        display: flex;
        align-items: center;
        margin-left: 20 * @pcx;
        cursor: pointer;

        svg {
          width: 24 * @pcx;
          fill: white;
        }

        .lang-icon {
          width: 33 * @pcx;
          //height: 33*@pcx;
          //border-radius: 50%;
          margin-right: 8 * @pcx;
        }

        .lang-list {
          margin-top: 25 * @pcx;
          width: max-content;
          line-height: 50 * @pcx;
          padding: 20 * @pcx;

          .lang-item {
            white-space: nowrap;

            &:nth-child(n + 2) {
              margin-top: 10 * @pcx;
            }
          }
        }
      }
    }
  }
}

.head-menu-bg {
  width: 100%;
  background: #1b1f28;
  padding: 0 80 * @pcx;

  .head-menu-list {
    width: 100%;
    margin: auto;
    height: 46 * @pcx;
    display: flex;
    align-items: center;

    .head-menu-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: Roboto, Roboto, sans-serif;
      font-weight: bold;
      font-size: 17 * @pcx;
      color: #ffffff;
      line-height: 20 * @pcx;
      text-align: left;
      font-style: normal;
      text-transform: none;
      cursor: pointer;
      position: relative;

      &::after {
        transition: width 0.3s;
        width: 0;
        position: absolute;
        bottom: 0;
        content: '';
        height: 3 * @pcx;
        background: linear-gradient(36.32682464449496deg, #e34853 0%, #e64750 62%, #755ce6 100%);
      }

      &.on,
      &:hover {
        background: linear-gradient(36.32682464449496deg, #e34853 0%, #e64750 62%, #755ce6 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &::after {
          width: 100%;
        }
      }

      &:nth-child(n + 2) {
        margin-left: 139 * @pcx;
      }

      img {
        height: 16 * @pcx;
      }
    }

    .prom-btn {
      margin-left: auto;
      display: flex;
      align-items: center;
      height: 36 * @pcx;
      font-family: Roboto, Roboto, sans-serif;
      font-weight: bold;
      font-size: 17 * @pcx;
      color: #fff8d4;
      line-height: 20 * @pcx;
      text-align: left;
      font-style: normal;
      text-transform: none;
      background: linear-gradient(to bottom, #e2c865, #af7f3b, #eed085);
      padding: 17 * @pcx;
      border-radius: 5 * @pcx;
      cursor: pointer;

      img {
        width: 25 * @pcx;
        height: 25 * @pcx;
        margin-right: 15 * @pcx;
      }
    }
  }
}
</style>
