<template>
  <div @click.stop class="menu-main overflow-hidden">
    <div class="acc-info-box">
<!--      <div class="acc-wrap">-->
<!--        <div class="acc-item">-->
<!--          <div class="acc-label">{{ $t('totalBalance') }}</div>-->
<!--          <div class="acc-val">{{ formatAmount(totalBalance.toFixed(2)) }}</div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="acc-wrap" >
        <div class="acc-item">
          <div class="acc-label">{{ $t('normalBalance') }}</div>
          <div class="acc-val">{{ formatAmount(cash)}}</div>
        </div>
      </div>
<!--      <div class="acc-wrap">-->
<!--        <div class="acc-item">-->
<!--          <div class="acc-label">{{ $t('normalBalance') }}</div>-->
<!--          <div class="acc-val">{{ formatAmount(balance) }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="acc-wrap">-->
<!--        <div class="acc-item">-->
<!--          <div class="acc-label">{{ $t('coupon2') }}</div>-->
<!--          <div class="acc-val">{{ formatAmount(coupon) }}</div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="acc-menu-list">
      <div v-for="o in accMenuList" :key="o.text" class="acc-menu-item" :class="{ on: o.isShowChildren }">
        <div class="acc-menu-title" @click="goView(o)" :class="{ on: o.path === currentRoute }">
          <img class="acc-menu-title-icon" :src="o.icon" alt="" />
          <div class="acc-menu-title-text">{{ o.text || $t(o.name) }}</div>
          <img class="acc-menu-title-btn" src="@/assets/img/icons/active/arrow.svg" alt="" v-if="o.children" />
        </div>
        <div class="acc-menu-sub-list" v-if="o.children">
          <div class="acc-menu-title" @click="goView(s)" v-for="s in o.children">
            <img class="acc-menu-title-icon" :src="s.icon" alt="" />
            <div class="acc-menu-title-text">{{ s.text || $t(s.name) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useCommonStore } from '@/stores/common.js'
import { useUserStore } from '@/stores/user.js'
import { useRoute, useRouter } from 'vue-router'
// import walletIcon from '@/assets/img/icons/my-account/active/wallet.svg';
import depositIcon from '@/assets/img/icons/my-account/active/deposit.webp'
import withdrawIcon from '@/assets/img/icons/my-account/active/withdraw.webp'
import bonus_couponIcon from '@/assets/img/v2/menu/coupon.webp'
import rollingIcon from '@/assets/img/v2/menu/rolling.webp'
import rebateIcon from '@/assets/img/v2/menu/rebate.webp'
import accountIcon from '@/assets/img/v2/menu/profile.webp'
import languageIcon from '@/assets/img/v2/menu/languageSettings.webp'

import cnIcon from '@/assets/img/icon/cn.webp'
import enIcon from '@/assets/img/icon/en.webp'
import koIcon from '@/assets/img/icon/ko.webp'
import thIcon from '@/assets/img/icon/th.webp'
import vnIcon from '@/assets/img/icon/vn.webp'
import mmIcon from '@/assets/img/icon/mm.webp'
import lkIcon from '@/assets/img/icon/lk.webp'

import noticeIcon from '@/assets/img/v2/menu/notices.webp'
import logoutIcon from '@/assets/img/v2/menu/logout.webp'

import { formatAmount } from '@/utils/utils.js'
import { useConfigStore } from '@/stores/config.js'

const closing = ref(false)
const { showMenu } = storeToRefs(useCommonStore())
const userStore = useUserStore()
const router = useRouter()
const configStore = useConfigStore()
const instance = getCurrentInstance()

const goView = (obj) => {
  if (obj.children) {
    obj.isShowChildren = !obj.isShowChildren
    return
  }

  switch (obj.path) {
    case 'logout':
      userStore.logout()
      showMenu.value = false
      break

    case '$lang':
      const language = ref(configStore.language)
      const i18n = instance.appContext.config.globalProperties.$i18n

      const name = obj.key
      configStore.language = name
      language.value = name
      i18n.locale = name
      break

    default:
      showMenu.value = false
      router.push(obj.path)
  }
}

const route = useRoute()
const currentRoute = computed(() => {
  return route.path
})

const accMenuList = ref([
  {
    name: 'deposit',
    icon: depositIcon,
    path: '/deposit',
  },
  {
    name: 'withdrawal',
    icon: withdrawIcon,
    path: '/withdrawal',
  },
  // {
  //     name: 'accMenuMessage',
  //     icon: messageIcon,
  //     path: '/notices',
  // },
  // {
  //     name: 'transactionHistory',
  //     icon: transaction_historyIcon,
  //     path: '/myaccount/records',
  // },
  // {
  //   name: 'coupon',
  //   icon: bonus_couponIcon,
  //   path: '/coupon',
  // },
  // {
  //   name: 'rolling',
  //   icon: rollingIcon,
  //   path: '/rolling',
  // },
  // {
  //   name: 'monetaryRewards',
  //   icon: rebateIcon,
  //   path: '/rebate',
  // },
  // {
  //     name: 'gameHistory',
  //     icon: transaction_historyIcon,
  //     path: '/game-history',
  // },
  // {
  //     name: 'referrer',
  //     icon: referrerIcon,
  //     path: '/referrer',
  // },
  {
    name: 'profile',
    icon: accountIcon,
    path: '/profile',
  },
  // {
  //     name: 'profile',
  //     icon: accountIcon,
  //     path: '/account-details',
  // },
  // {
  //     name: 'bankDetails',
  //     icon: bankIcon,
  //     path: '/bank-details',
  // },
  // {
  //     name: 'changePassword',
  //     icon: passwordIcon,
  //     path: '/change-password',
  // },
  // {
  //     name: 'languageSettings',
  //     icon: languageIcon,
  //     isShowChildren: false,
  //     children: [
  //         {
  //             text: '中文',
  //             icon: cnIcon,
  //             key: 'cn',
  //             path: '$lang',
  //         },
  //         {
  //             text: 'English',
  //             icon: enIcon,
  //             key: 'en',
  //             path: '$lang',
  //         },
  //         {
  //             text: '한국어',
  //             icon: koIcon,
  //             key: 'ko',
  //             path: '$lang',
  //         },
  //         {
  //             text: 'Tiếng Việt',
  //             icon: vnIcon,
  //             key: 'vn',
  //             path: '$lang',
  //         },
  //         {
  //             text: 'ภาษาไทย',
  //             icon: thIcon,
  //             key: 'th',
  //             path: '$lang',
  //         },
  //         {
  //             text: 'မြန်မာ',
  //             icon: mmIcon,
  //             key: 'mm',
  //             path: '$lang',
  //         },
  //         {
  //             text: 'ශ්‍රී ලංකා',
  //             icon: lkIcon,
  //             key: 'lk',
  //             path: '$lang',
  //         },
  //     ],
  // },
  {
    name: 'notices',
    icon: noticeIcon,
    path: '/notes',
  },
  // {
  //   name: 'helpCenter',
  //   icon: helpIcon,
  //   path: '/support',
  // },
  // {
  //     name: 'aboutUs',
  //     icon: aboutUsIcon,
  //     path: '/aboutUs',
  // },
  {
    name: 'logout',
    icon: logoutIcon,
    path: 'logout',
  },
])

const totalBalance = computed(
  () =>
    Number(userStore.balance) +
    Number(userStore.cash > 0 ? userStore.cash : 0) +
    Number(userStore.sport_bonus) +
    Number(userStore.live_slots_bonus) +
    Number(userStore.coupon)
)
const balance = computed(() => userStore.balance)
const cash = computed(() => userStore.cash)
const sport_bonus = computed(() => userStore.sport_bonus)
const live_slots_bonus = computed(() => userStore.live_slots_bonus)
const coupon = computed(() => userStore.coupon)

const showBalanceDetail = ref(false)

userStore.updateInfo()
</script>

<style scoped lang="less">
.balance-arrow {
  content: url('@/assets/img/icons/inactive/arrow.svg');
  width: 10 * @pcx;
  height: auto;
  transform: rotate(90deg);
  transition: transform 0.3s;
  margin: 0 auto;
}

.balance-arrow.down {
  transform: rotate(270deg);
}

.menu-main {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  font-size: 18 * @pcx;
  padding-bottom: 10 * @pcx;
}

.acc-info-box {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 16 * @pcx;

  .acc-wrap {
    flex: 0 0 100%;
    padding: 5 * @pcx;
    overflow: hidden;
  }

  .acc-item {
    height: 63 * @pcx;
    background: #181f2c;
    border-radius: 10 * @pcx;
    border: 1 * @pcx solid #404756;
    font-family: Roboto, Roboto, sans-serif;
    font-weight: bold;
    font-size: 17 * @pcx;
    line-height: 20 * @pcx;
    text-align: center;
    font-style: normal;
    text-transform: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .acc-label {
      color: #a7a7a7;
    }

    .acc-val {
      margin-top: 6 * @pcx;
      color: #b88100;
    }
  }
}

.acc-menu-list {
  margin-top: 15 * @pcx;
  flex: 1;
  overflow: auto;

  .acc-menu-item {
    color: #ffffff;

    &.on {
      .acc-menu-title-btn {
        transform: rotateZ(-90deg) !important;
      }

      .acc-menu-sub-list {
        max-height: 100vh;
      }
    }

    .acc-menu-title {
      //border-top: 1*@pcx solid #5d6270;
      display: flex;
      align-items: center;
      height: 52 * @pcx;
      padding: 0 85 * @pcx;
      cursor: pointer;

      &.on {
        background: #1f2638;
        color: white;

        .acc-menu-title-icon {
          filter: brightness(10);
        }
      }

      .acc-menu-title-icon {
        flex: 0 0 auto;
        height: 30 * @pcx;
        margin-right: 16 * @pcx;
      }

      .acc-menu-title-text {
        flex: 1;
        font-family: Roboto, Roboto, sans-serif;
        font-weight: bold;
        font-size: 17 * @pcx;
        color: #ffffff;
        line-height: 20 * @pcx;
        font-style: normal;
        text-transform: none;
      }

      .acc-menu-title-btn {
        flex: 0 0 auto;
        width: 20 * @pcx;
        height: 20 * @pcx;
        transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transform-origin: center center;
        transform: rotateZ(90deg);
      }
    }

    .acc-menu-sub-list {
      overflow: hidden;
      padding-left: 10 * @pcx;
      background-color: #373942;
      max-height: 0;
      transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
}
</style>
