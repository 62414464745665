import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView.vue'),
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/RegisterView.vue'),
    },
    {
      path: '/sports',
      name: 'sports',
      meta: {
        auth: true,
      },
      component: () => import('@/views/sports.vue'),
    },
    {
      path: '/game/:providerId/:gameId',
      name: 'gameIFrame',
      component: () => import('@/views/GameIFrameView.vue'),
    },
    // {
    //     path: '/bti-sports',
    //     name: 'bti-sports',
    //     meta: {
    //         providerId: 30008,
    //         gameId: 0,
    //         url: '',
    //         auth: true,
    //     },
    //     component: () => import('@/views/IFrameView.vue'),
    // },
    // {
    //     path: '/betconstruct',
    //     name: 'betconstruct',
    //     meta: {
    //         url: '',
    //         auth: true
    //     },
    //     component: () => import('@/views/IFrameView.vue'),
    // },
    {
      path: '/live-casino',
      name: 'live-casino',
      meta: {
        auth: true,
      },
      component: () => import('@/views/LiveCasino.vue'),
    },
    {
      path: '/slots',
      name: 'slots',
      meta: {
        auth: true,
      },
      component: () => import('@/views/SlotsView.vue'),
    },
    {
      path: '/live-hold',
      name: 'live-hold',
      meta: {
        url: '/',
        auth: true,
      },
      component: () => import('@/views/JumpView.vue'),
    },
    {
      path: '/vip',
      name: 'vip',
      meta: {
        auth: true,
      },
      component: () => import('@/views/VIP.vue'),
    },
    // {
    //   path: '/promotions',
    //   name: 'promotions',
    //   meta: {
    //     auth: true,
    //   },
    //   component: () => import('@/views/PromotionView.vue'),
    // },
    {
      path: '/sports-betting-game-rules',
      name: 'sports-betting-game-rules',
      meta: {
        auth: true,
      },
      component: () => import('@/views/RuleView.vue'),
    },
    {
      path: '/game-responsibility',
      name: 'game-responsibility',
      meta: {
        auth: true,
      },
      component: () => import('@/views/intro/GameResponsibility.vue'),
    },
    {
      path: '/terms-and-conditions',
      name: 'terms-and-conditions',
      meta: {
        auth: true,
      },
      component: () => import('@/views/intro/TermsAndConditions.vue'),
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      meta: {
        auth: true,
      },
      component: () => import('@/views/intro/PrivacyPolicy.vue'),
    },
    {
      path: '/deposit',
      name: 'deposit',
      meta: {
        auth: true,
      },
      component: () => import('@/views/Deposit/Deposit.vue'),
    },
    {
      path: '/withdrawal',
      name: 'withdrawal',
      meta: {
        auth: true,
      },
      component: () => import('@/views/Withdraw/Withdrawal.vue'),
    },
    {
      path: '/notices',
      name: 'notices',
      meta: {
        auth: true,
      },
      component: () => import('@/views/Notices.vue'),
    },
    {
      path: '/notes',
      name: 'notes',
      meta: {
        auth: true,
      },
      component: () => import('@/views/notes.vue'),
    },
    // {
    //   path: '/coupon',
    //   name: 'coupon',
    //   meta: {
    //     auth: true,
    //   },
    //   component: () => import('@/views/Coupon.vue'),
    // },
    // {
    //   path: '/rolling',
    //   name: 'rolling',
    //   meta: {
    //     auth: true,
    //   },
    //   component: () => import('@/views/Rolling.vue'),
    // },
    // {
    //   path: '/rebate',
    //   name: 'rebate',
    //   meta: {
    //     auth: true,
    //   },
    //   component: () => import('@/views/RebateRecords.vue'),
    // },
    // {
    //   path: '/game-history',
    //   name: 'gameHistory',
    //   meta: {
    //     auth: true,
    //   },
    //   component: () => import('@/views/GameHistory.vue'),
    // },
    // {
    //   path: '/referrer',
    //   name: 'referrer',
    //   meta: {
    //     auth: true,
    //   },
    //   component: () => import('@/views/Referrer.vue'),
    // },
    // {
    //     path: '/terms-and-conditions',
    //     name: 'termsAndConditions',
    //     meta: {
    //         auth: true
    //     },
    //     component: () => import('@/views/TermsAndConditions.vue'),
    // },

    {
      path: '/support',
      name: 'support',
      meta: {
        auth: true,
      },
      component: () => import('@/views/support.vue'),
    },
    {
      path: '/change-password',
      name: 'change-password',
      meta: {
        auth: true,
      },
      component: () => import('@/views/change-password.vue'),
    },
    {
      path: '/bank-details',
      name: 'bank-details',
      meta: {
        auth: true,
      },
      component: () => import('@/views/bank-details.vue'),
    },
    {
      path: '/account-details',
      name: 'account-details',
      meta: {
        auth: true,
      },
      component: () => import('@/views/account-details.vue'),
    },
    {
      path: '/wallet',
      name: 'wallet',
      meta: {
        auth: true,
      },
      component: () => import('@/views/wallet.vue'),
    },
    {
      path: '/profile',
      name: 'Profile',
      meta: {
        auth: true,
      },
      component: () => import('@/views/Profile.vue'),
    },
    {
      path: '/aboutUs',
      name: 'aboutUs',
      meta: {
        auth: true,
      },
      component: () => import('@/views/aboutUs.vue'),
    },
    {
      path: '/Maintenance',
      name: 'maintenance',
      component: () => import('@/views/Maintenance.vue'),
    },
  ],
})

export default router
